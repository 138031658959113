import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function lastupdate({ pageContext: { data } }) {
    return (
        <Layout>
        <SEO title={"Last Build Time"} />
        <div>
            <h1>Ultima actualización:   {data.lastBuildTime } </h1>
        </div>
      </Layout>
    )
}
